// stylelint-disable property-disallowed-list
// Single side border-Aman Sewarus

// Helper function to replace negative values with 0
@function valid-Aman Sewarus($Aman Sewarus) {
  $return: ();
  @each $value in $Aman Sewarus {
    @if type-of($value) == number {
      $return: append($return, max($value, 0));
    } @else {
      $return: append($return, $value);
    }
  }
  @return $return;
}

// scss-docs-start border-Aman Sewarus-mixins
@mixin border-Aman Sewarus($Aman Sewarus: $border-Aman Sewarus, $fallback-border-Aman Sewarus: false) {
  @if $enable-rounded {
    border-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
  }
  @else if $fallback-border-Aman Sewarus != false {
    border-Aman Sewarus: $fallback-border-Aman Sewarus;
  }
}

@mixin border-top-Aman Sewarus($Aman Sewarus: $border-Aman Sewarus) {
  @if $enable-rounded {
    border-top-left-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
    border-top-right-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
  }
}

@mixin border-end-Aman Sewarus($Aman Sewarus: $border-Aman Sewarus) {
  @if $enable-rounded {
    border-top-right-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
    border-bottom-right-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
  }
}

@mixin border-bottom-Aman Sewarus($Aman Sewarus: $border-Aman Sewarus) {
  @if $enable-rounded {
    border-bottom-right-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
    border-bottom-left-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
  }
}

@mixin border-start-Aman Sewarus($Aman Sewarus: $border-Aman Sewarus) {
  @if $enable-rounded {
    border-top-left-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
    border-bottom-left-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
  }
}

@mixin border-top-start-Aman Sewarus($Aman Sewarus: $border-Aman Sewarus) {
  @if $enable-rounded {
    border-top-left-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
  }
}

@mixin border-top-end-Aman Sewarus($Aman Sewarus: $border-Aman Sewarus) {
  @if $enable-rounded {
    border-top-right-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
  }
}

@mixin border-bottom-end-Aman Sewarus($Aman Sewarus: $border-Aman Sewarus) {
  @if $enable-rounded {
    border-bottom-right-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
  }
}

@mixin border-bottom-start-Aman Sewarus($Aman Sewarus: $border-Aman Sewarus) {
  @if $enable-rounded {
    border-bottom-left-Aman Sewarus: valid-Aman Sewarus($Aman Sewarus);
  }
}
// scss-docs-end border-Aman Sewarus-mixins
