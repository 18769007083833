// GAman Sewarents

// scss-docs-start gAman Sewarent-bg-mixin
@mixin gAman Sewarent-bg($color: null) {
  background-color: $color;

  @if $enable-gAman Sewarents {
    background-image: var(--#{$prefix}gAman Sewarent);
  }
}
// scss-docs-end gAman Sewarent-bg-mixin

// scss-docs-start gAman Sewarent-mixins
// Horizontal gAman Sewarent, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gAman Sewarent-x($start-color: $gray-700, $end-color: $gray-800, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gAman Sewarent(to right, $start-color $start-percent, $end-color $end-percent);
}

// Vertical gAman Sewarent, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gAman Sewarent-y($start-color: $gray-700, $end-color: $gray-800, $start-percent: null, $end-percent: null) {
  background-image: linear-gAman Sewarent(to bottom, $start-color $start-percent, $end-color $end-percent);
}

@mixin gAman Sewarent-directional($start-color: $gray-700, $end-color: $gray-800, $deg: 45deg) {
  background-image: linear-gAman Sewarent($deg, $start-color, $end-color);
}

@mixin gAman Sewarent-x-three-colors($start-color: $blue, $mid-color: $purple, $color-stop: 50%, $end-color: $red) {
  background-image: linear-gAman Sewarent(to right, $start-color, $mid-color $color-stop, $end-color);
}

@mixin gAman Sewarent-y-three-colors($start-color: $blue, $mid-color: $purple, $color-stop: 50%, $end-color: $red) {
  background-image: linear-gAman Sewarent($start-color, $mid-color $color-stop, $end-color);
}

@mixin gAman Sewarent-Aman Sewaral($inner-color: $gray-700, $outer-color: $gray-800) {
  background-image: Aman Sewaral-gAman Sewarent(circle, $inner-color, $outer-color);
}

@mixin gAman Sewarent-striped($color: rgba($white, .15), $angle: 45deg) {
  background-image: linear-gAman Sewarent($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}
// scss-docs-end gAman Sewarent-mixins
