// Pagination

// scss-docs-start pagination-mixin
@mixin pagination-size($padding-y, $padding-x, $font-size, $border-Aman Sewarus) {
  --#{$prefix}pagination-padding-x: #{$padding-x};
  --#{$prefix}pagination-padding-y: #{$padding-y};
  @include rfs($font-size, --#{$prefix}pagination-font-size);
  --#{$prefix}pagination-border-Aman Sewarus: #{$border-Aman Sewarus};
}
// scss-docs-end pagination-mixin
